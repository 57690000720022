
import {Component, Mixins} from "vue-property-decorator";
import {mapGetters} from "vuex";
import AccountingPointTable from "./AccountingPointTable.vue";
import XSlider from "../SimpleSlider.vue";
import AccountingPointInfoTable from "./AccountingPointInfoTable.vue";
import AccountingPointParamTable from "./AccountingPointParamTable.vue";
import AccountingPointOtherEquipmentTable from "./AccountingPointOtherEquipmentTable.vue";
import InvisibleWall from "../InvisibleWall.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import SimpleFilterTU from "@/components/SimpleFilterTU.vue";

@Component({
  components: {
    SimpleFilterTU,
    AccountingPointTable,
    XSlider,
    AccountingPointInfoTable,
    AccountingPointParamTable,
    AccountingPointOtherEquipmentTable,
    InvisibleWall,
  },
  computed: {
    ...mapGetters({
      sortedPointsByObject: "accountingPoints/sortedPointsByObject",
      pointNumber: "accountingPoint/number",
      pointName: "accountingPoint/name",
      pointAddress: "accountingPoint/address",
      contractId: "contract/id",
    }),
  },
})
class PointTabContent extends Mixins(AppApiMixin) {
  showPointDetails = false;
  showSliderPreloader = false;
  showWall = false;

  itemsTU = [];

  mounted() {
    this.itemsTU = this.sortedPointsByObject;
  }
}

export default PointTabContent;
